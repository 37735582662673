'use strict';

$(() => {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('#policy-job').on("change", function () {
        $(this).val($(this).prop("checked") ? 1 : 0);
    });

    const messageContactEs = $('html').attr('lang') == 'es' ? {
        messages: {
            'name': {
                required: 'El campo nombre y apellidos es obligatorio',
                maxlength: 'El campo no puede tener mas de 120 caracteres',
            },
            'email': {
                required: 'El campo correo electrónico es obligatorio',
                email: 'El campo debe contener un correo electrónico',
                maxlength: 'El campo no puede tener mas de 120 caracteres',
            },
            'phone': {
                required: 'El campo número de teléfono es obligatorio',
                number: 'El campo debe contener números',
                maxlength: 'El campo no puede tener mas de 15 caracteres',
                minlength: 'El campo no puede tener menos de 9 caracteres',
            },
            'comment': {
                required: 'El campo comentario es obligatorio',
                maxlength: 'El campo no puede tener mas de 1200 caracteres',
            },
            'job': {
                required: 'El campo puesto es obligatorio',
                
            },
            'other_job': {
                required: 'El campo otro puesto es obligatorio',
                maxlength: 'El campo no puede tener mas de 30 caracteres',
            },
            'policy': {
                required: 'Debe aceptar la política de privacidad para enviar los datos',
            },
            'cv': {
                required: 'El campo cv es obligatorio',
            },
        }
    } : {};

    $('#job-form-data').validate({
        rules: {
            'name': {
                required: true,
                maxlength: 120,
            },
            'email': {
                required: true,
                maxlength: 120,
                email: true
            },
            'phone': {
                required: true,
                number: true,
                maxlength: 15,
                minlength: 9,
            },
            'job': {
                required: true
            },
            'comment': {
                required: true,
                maxlength: 1200,
            },
            'policy': {
                required: true
            },
            'cv': {
                required: true
            },
            'other_job': {
                required: true,
                maxlength: 30,
            }
        },
        ...messageContactEs,
        errorPlacement: function (error, element) {
            const el = $(element);
            el.parent().after(error)
        }
    });

    $('#job').on('change', function () {
        const option = $(this).val();
        let other = $('#other-job');

        if (option == 'Otros') {
            other.parent().removeClass('d-none')
        } else {
            other.parent().addClass('d-none')
            other.val(' ');
        }
    });

    $('#job-send').on('click', function () {
        grecaptcha.ready(() => {

            const form = $('#job-form-data');

            if (!form.valid()) return;
            grecaptcha.execute(reCAPTCHA_site_key, { action: 'JOB' }).then((token) => {

                let cv = $('#cv').prop('files')[0];
                if (!$('#cv').prop('files').length) {
                    return;
                }

                let othersData = form.serializeArray();
                let form_data = new FormData();
                form_data.append('cv', cv);
                form_data.append('g-recaptcha-response', token);

                for (const key in othersData) {
                    if (Object.hasOwnProperty.call(othersData, key)) {
                        const element = othersData[key];
                        let jsonStr = JSON.stringify(element.value);
                        let json = JSON.parse(jsonStr);

                        form_data.append(element.name, json);
                    }
                }

                showLoaded();

                $.ajax({
                    url: `/job`,
                    cache: false,
                    contentType: false,
                    processData: false,
                    data: form_data,
                    type: 'post',
                    success: (res) => {

                        Swal.fire(
                            {
                                title: res.title,
                                text: res.message,
                                icon: 'success',
                            }
                        ).then(() => {
                            $('#job-modal').modal('hide');
                            $('#job-form-data').trigger('reset');
                        });
                    },
                    error: (err) => {
                        let errors = err.responseJSON;
                        let list = '';

                        if (errors.hasOwnProperty('errors')) {
                            errors = errors.errors;
                            for (const key in errors) {
                                if (Object.hasOwnProperty.call(errors, key)) {
                                    const element = errors[key].map((text) => `<p>${text}</p>`).join('');
                                    list += element;
                                }
                            }
                        } else if (errors.hasOwnProperty('message')) {
                            list = errors.message;
                        } else {
                            list = 'Error 500';
                        }

                        Swal.fire(
                            {
                                title: 'Error',
                                html: list,
                                icon: 'error',
                            }
                        )
                    },
                    complete: () => {
                        hideLoaded();
                    }
                });
            });
        });
    });


    $('#cv').on('change', function () {

        let size_kb = this.files[0].size / 1024;
        let fileType = this.files[0].type;

        let allowFiles = ['application/pdf'];
        let cv = document.getElementById("cv")

        if (!allowFiles.includes(fileType)) {
            Swal.fire({
                title: '¡Error!',
                text: 'Solo se admiten documentos PDF',
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            });
            cv.value = "";
            if(!/safari/i.test(navigator.userAgent)){
                cv.type = ''
                cv.type = 'file'
              }
        }

        if (size_kb > 2048) {

            Swal.fire({
                title: '¡Error!',
                text: 'La imagen no puede ser mayor a 2mb. Peso de la imagen actual subida: ' + (size_kb / 1000).toFixed(2) + ' mb aproximadamente.',
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            });
            cv.value = "";
            if(!/safari/i.test(navigator.userAgent)){
                cv.type = ''
                cv.type = 'file'
              }

        }
    });

    function showLoaded() {
        $('body').append('<div id="bg-load"><i class="fas fa-circle-notch fa-spin fa-2x"></i></div>');
    }
    function hideLoaded() {
        $('#bg-load').remove();
    }

    const myModalEl = document.getElementById('job-modal')
    myModalEl.addEventListener('hidden.bs.modal', function (event) {
        $('#job-form-data').trigger('reset');
        $('#job-modal label.error').remove();
    })
});